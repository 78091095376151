export default class Animations {
    static animations = new Animations()

    fadeInScreen(screen_name) {
        const screen = document.getElementById(screen_name);
        if(!screen || !screen_name) return;
        if(screen){
            screen.style.opacity = "5";
            screen.style.transition = "translateY(1px)";
        }
    }
}