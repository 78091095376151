import React from "react";
import './AboutMe.css';
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/animations";

export default function AboutMe(props) {
    let fadeInScreenHandler = (screen) => {
        if (screen.fadeScreen !== props.id) return;
        Animations.animations.fadeInScreen(props.id)
    }
    const fadeInSubscription =
        ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler)
    const SCREEN_CONSTANTS = {
        description: "Born in 1998, started programming at the age of 8, with the help of my father, who is also a software engineer. at that age I was known as the 'Wonder kid' of programming in my country. later I got accepted in the national organization of development of exceptional talents, where I was able to learn more about programming and computer, and that was the time I started winning awards and competitions like ACM, HelliNet, Kharazmi and... .Then I got accepted at WTIAU, where I started my bachelor's degree in software engineering. At that age I started to work and teach at the same time in the programming field. After finishing my bachelor's degree, I needed to move to more developed countries to learn more and get more experience, so I can reach my goals and dreams in programming. So I decided to move to Italy, and I got accepted for a master's degree in computer engineering at the Politecnico di Torino, where I am currently studying. I moved to Torino in November 2022, and I got my first job in Italy as a software engineer in a company called 'Meyler s.r.l' at May 2023, where I am currently working.",
        highlights: {
            bullets: [
                "Programming since 2006",
                "Won multiple awards and competitions",
                "Worked and taught programming ",
                "Bachelor's degree in Software Engineering",
                "Studying Master's degree at Politecnico di Torino",
                "Professional in most programming languages",
                "C++, Python, Java, JavaScript, HTML, CSS, SQL, PHP, ...",
                "Working as a Software Engineer at Meyler s.r.l",
            ],
            heading: "Here are a few highlights of my journey so far:"
        }
    }
    const renderHighlight = () => {
        return (
            SCREEN_CONSTANTS.highlights.bullets.map((value, i) => (
                <div className="highlight" key={i}>
                    <div className="highlight-blob"></div>
                    <span>{value}</span>
                </div>
            ))
        )
    }

    return (
        <div className="about-me-container screen-container" id="AboutMe">
            <div className="about-me-parent">
                <ScreenHeading title={"About Me"} subHeading={"Who am I?"}/>
                <div className="about-me-card">
                    <div className="about-me-profile">

                    </div>
                    <div className="about-me-texts">
                        <div className="about-me-details">
                        <span className="about-me-description">
                            {SCREEN_CONSTANTS.description}
                        </span>
                        </div>
                        <div className="about-me-highlights">
                            <div className="highlight-heading">
                                <span>{SCREEN_CONSTANTS.highlights.heading}</span>
                            </div>
                            {renderHighlight()}
                        </div>
                        <div className="about-me-options">
                            <button className="btn primary-btn">
                                {" "}
                                Contact Me
                            </button>
                            <a href="https://amiriravani.tiiny.site" download="AmirIravani.pdf">
                                <button className="btn highlighted-btn">
                                    Get Resume
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}