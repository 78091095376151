import React  from "react";
import './footer.css';

export default function Footer() {
    return (
        <div className="footer-container">
            <div className={"footer-parent"}>
                <img src={require('../../../assets/home/shape-bg.png')} alt="No Internet Connection" className="footer-logo"/>
            </div>
        </div>
    )
}