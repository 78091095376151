import React from 'react';
import Typical from 'react-typical';
import './Profile.css';

export default function Profile() {
    return (
        <div className="profile-container">
            <div className="profile-parent">
                <div className="profile-details">
                    <div className="colz">
                        <div className="colz-icon">
                            <a href='https://www.instagram.com/amirmohammadirv'>
                                <i className='fa fa-instagram'></i>
                            </a>
                            <a href='https://it.linkedin.com/in/amirmohammad-iravani'>
                                <i className='fa fa-linkedin-square'></i>
                            </a>
                        </div>
                    </div>
                    <div className="profile-details-name">
                          <span className="primary-text">
                              {" "}
                              Hello! I'm <span className="highlight-text">Amir Iravani</span>
                          </span>
                    </div>
                    <div className="profile-details-role">
                            <span className="primary-text">
                                {" "}
                                <h1>
                                    <Typical
                                        steps={[
                                            "Senior Front end Developer👨‍💻",
                                            3000,
                                            "Trying to be full Stack Developer⌛",
                                            3000,
                                            "Software Engineer💻",
                                            3000,
                                            "Enthusiastic web and app Developer🤩",
                                            3000,
                                            "AI lover and enthusiast🤖",
                                            3000,
                                        ]}
                                        loop={Infinity}
                                    />
                                </h1>
                                <span className="profile-role-tagline">
                                    I design and code things, and I love what I do.
                                </span>
                            </span>
                    </div>
                    <div className="profile-options">
                        <button className="btn primary-btn">
                            {" "}
                            Contact Me
                        </button>
                        <a href="https://amiriravani.tiiny.site" download="AmirIravani.pdf">
                            <button className="btn highlighted-btn">
                                Get Resume
                            </button>
                        </a>
                    </div>
                </div>
                <div className="profile-picture">
                    <div className="profile-picture-background">
                    </div>
                </div>
            </div>
        </div>
    );
}