import {TOTAL_SCREENS} from "./commonUtils";
import {Subject} from "rxjs";

export default class ScrollService {
    static scrollHandler = new ScrollService();
    static currentScreenBroadCaster = new Subject();;
    static currentScreenFadeIn = new Subject();


    constructor(){
        window.addEventListener('scroll', this.checkCurrentScreenUnderViewPort);
    }
    scrollToHireMe = () => {
        let contactMeScreen = document.getElementById("Contact Me");
        if(!contactMeScreen) return;
        if(contactMeScreen){
            contactMeScreen.scrollIntoView({behavior: "smooth"});
        }
    }
    scrollToHome = () => {
        let HomeScreen = document.getElementById("Home");
        if(!HomeScreen) return;
        if(HomeScreen){
            HomeScreen.scrollIntoView({behavior: "smooth"});
        }
    }
    isElementInView =(elem, type) => {
        let rect = elem.getBoundingClientRect();
        let elementTop = rect.top;
        let elementBottom = rect.bottom;

        let partiallyVisible = (elementBottom >= 0) && (elementTop < window.innerHeight);
        let completelyVisible = (elementTop >= 0) && (elementBottom <= window.innerHeight);

        switch(type){
            case "partial":
                return partiallyVisible;
            case "complete":
                return completelyVisible;
            default:
                return false;
        }
    }
    checkCurrentScreenUnderViewPort = (event) => {
        if(!event) return;
        for(let screen of TOTAL_SCREENS){
            let screenFromDOM = document.getElementById(screen.screen_name);
            if(!screenFromDOM) {
                continue;
            }
            let fullyVisible = this.isElementInView(screenFromDOM, "complete");
            let partiallyVisible = this.isElementInView(screenFromDOM, "partial");

            if(fullyVisible || partiallyVisible){
                if(partiallyVisible && !screen.alreadyRendered){
                    ScrollService.currentScreenFadeIn.next({
                        fadeInScreen: screen.screen_name,
                    });
                    screen['alreadyRendered'] = true;
                    break;
                }
                if(fullyVisible){
                    ScrollService.currentScreenBroadCaster.next({
                        screenInView: screen.screen_name,
                    });
                    break;
                }
            }
        }
    }

}